import { formatDate } from '../../util/date.util.ts'
import { trpc } from '../../util/trpc.ts'
import { Fragment, useEffect, useState } from 'react'
import { first, max, orderBy, toPairs, values } from 'lodash-es'
import TrpcWrapper from '../context/TrpcReact.context.tsx'
import { endOfDay, isSameDay, parse, subDays } from 'date-fns'

type Props = {
  nextDate: Date
}

export default ({ nextDate }: Props) => {
  return (
    <TrpcWrapper>
      <Content nextDate={nextDate} />
    </TrpcWrapper>
  )
}

const Content = ({ nextDate }: Props) => {
  const listDate = isSameDay(nextDate, new Date())
    ? [
        {
          title: 'Hôm qua',
          date: endOfDay(subDays(new Date(), 1)),
        },
        {
          title: 'Hôm kia',
          date: endOfDay(subDays(new Date(), 2)),
        },
      ]
    : [
        {
          title: 'Hôm nay',
          date: endOfDay(new Date()),
        },
        {
          title: 'Hôm qua',
          date: endOfDay(subDays(new Date(), 1)),
        },
      ]

  const [date, setDate] = useState(first(listDate)?.date || new Date())

  useEffect(() => {
    //@ts-ignore
    $('.datepicker').datepicker({
      dateFormat: 'dd/mm/yy',
      maxDate: subDays(nextDate, 1),
      onSelect: (dateText: string) => {
        setDate(parse(dateText, 'dd/MM/yyyy', new Date()))
      },
    })
  }, [])

  return (
    <>
      <div className="text-left mt-10 text-sm">
        &gt;&gt; Xem lô top{' '}
        {listDate.map((it, index) => (
          <Fragment key={index}>
            <a
              className="chotdaysw cursor-pointer"
              onClick={() => setDate(it.date)}
              style={{ color: isSameDay(it.date, date) ? '#f30' : undefined }}
            >
              {it.title}
            </a>{' '}
            |{' '}
          </Fragment>
        ))}
        <input placeholder="Chọn ngày" className="datepicker inline w-[70px] placeholder-black" />
      </div>

      <Table date={date} textClass="text-[#555]" />
      <Table date={nextDate} textClass="text-[#074aea] " />
    </>
  )
}

type TableProps = {
  date: Date
  textClass: string
}

const Table = ({ date, textClass }: TableProps) => {
  const [rate, setRate] = useState(1)
  const { data } = trpc.ghilo.loTop.useQuery({
    date: date.toISOString(),
  })

  useEffect(() => {
    if (!data) return
    setRate(34 / max(values(data as any)))
  }, [data])

  return (
    <div className="mx-1 border-[1px] rounded-[3px] border-solid border-[#ddd] mt-5">
      <div className="font-semibold text-white text-sm text-center bg-[#007cc2] h-10 flex items-center justify-center rounded-[3px]">
        <div>Bảng lô top ngày {formatDate(date)}</div>
      </div>

      <div className="contentbox_body">
        <div>
          <div className="px-2 w-full break-words">
            {data &&
              orderBy(toPairs(data), '1', 'desc').map(([key, value]) => {
                return (
                  <a
                    key={key}
                    className={`mr-3 hover:text-[#df5900] cursor-pointer ${textClass}`}
                    style={{
                      fontFamily: 'arial',
                      fontSize: value * rate,
                      display: 'inline-block',
                    }}
                    title={`${value} người chơi`}
                  >
                    <span style={{ alignSelf: 'center' }}>
                      {parseInt(key) < 10 ? `0${key}` : key}
                    </span>
                  </a>
                )
              })}

            {/*<div style={{ paddingTop: 5 }}>*/}
            {/*  <a>&gt;&gt; Xem tất cả</a>*/}
            {/*</div>*/}
          </div>
        </div>
      </div>
    </div>
  )
}
